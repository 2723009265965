import Banner from "./Banner";
import React from "react";

function FaqsList(props) {
  return (
    <Banner className="border-t border-gray-300">
      {props.nodes &&
        props.nodes.map((node, index) => (
          <Banner.Entity
            key={index}
            className="border-b border-gray-300 py-8 md:py-10"
          >
            <Banner.Question>{node.question}</Banner.Question>
            <Banner.StyledContent blocks={node._rawAnswer}>
              {node._rawAnswer}
            </Banner.StyledContent>
          </Banner.Entity>
        ))}
    </Banner>
  );
}

export default FaqsList;
