import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import ButtonSolid from "../../components/Button/ButtonSolid";

const FaqSection = ({ headingLevel, className, sectionTitle, sectionDesc }) => {
  return (
    <section className="relative mb-10 pt-16 pb-36 md:mb-20 md:pt-24 md:pb-48">
      <div className="absolute bottom-0 left-0 hidden h-full w-full md:block">
        <StaticImage
          src="../../images/6.0 FAQs/Hero-Desktop.jpg"
          loading="eager"
          placeholder="none"
          layout="fullWidth"
          className="h-full"
          imgClassName="object-bottom"
        />
      </div>
      <div className="absolute bottom-0 left-0 h-full w-full md:hidden">
        <StaticImage
          src="../../images/reviews/review_bg.png"
          loading="eager"
          placeholder="none"
          className="h-full w-full"
          imgClassName="object-bottom"
        />
      </div>

      <div className="container relative">
        <header className="mx-auto text-center md:max-w-[640px]">
          <h1 className="text-white">{sectionTitle}</h1>
          <p className="text-white">{sectionDesc}</p>
          <ButtonSolid modal="modal-contact" text="Contact Us" />
        </header>
      </div>
    </section>
  );
};

export default FaqSection;
